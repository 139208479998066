export const projects = [
  {
    title: "Cloud Security Threats 2024",
    subtitle: "General Security",
    description:
      "While the specific techniques hackers use may change from year to year, the methodologies used behind their targeted attacks often remain the same.",
    image: "./breach_cost_industry.png",
    link: "https://overkast.medium.com/top-cloud-security-threats-of-2024-9e137da41fa2",
  },
  {
    title: "Cloud Security Compliance",
    subtitle: "Compliance",
    description:
      "There are several key regulatory compliance frameworks companies using cloud infrastructure must adhere to if they wish to avoid paying large fines.",
    image: "./noncompliance.jpg",
    link: "https://overkast.medium.com/the-ultimate-guide-to-cloud-security-compliance-navigating-regulations-and-standards-f364faab82a0",
  }
];

export const services = [
  "AWS, GCP, and Azure Expertise",
  "Cloud Security Audits",
  "Attack Surface Reduction",
  "Compliance and Governance",
  "Cloud Asset Management",
  "Custom Security Software Development",
  "Incident Response and Remediation",
  "General Security Consulting"
];

export const testimonials = [
  {
    quote:
      "Very cool guy. Some say the coolest guy around, but the competition is a bit political so it's hard to know for sure. The judges are in on it and it's all about who you know. Anyways, like I said, very cool guy. Don't question that.",
    image: "https://www.writeups.org/wp-content/uploads/Hulk-Hollywood-Hogan-wrestler.jpg",
    name: "Hulk Hogan",
    company: "Cool Guy Incorporated",
  },
  {
    quote:
      "He saved my baby from a burning building that was also underwater. I don't know how he did it but I owe him my life. Also my baby's life, the one that he saved. The one I mentioned a few seconds ago. That baby.",
    image: "https://images.mubicdn.net/images/cast_member/532823/cache-439343-1559022206/image-w856.jpg",
    name: "Samir Al-Hajeed",
    company: "Underwater Building Co.",
  },
];