import React from 'react';
import Services from './Services';
import Testimonials from './Testimonials';
import About from './About';
import Research from './Research';
import Contact from './Contact';

function Home({ defaultSection }) {

    const navigateToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop = section.offsetTop - 60;
            window.scrollTo({
              top: offsetTop,
              behavior: 'smooth'
            });
        }
    };

  return (
    <main>
      {/* Home Section */}
      <section id="home">
        <div className="container px-5 py-10 mx-auto text-center">
            <img src="./Cloud-icon.png" className="w-32 inline-block mb-4" alt="Simple placeholder logo"/>
            <h1 className="sm:text-5xl text-4xl font-medium title-font text-white mb-10">
            Simplifying and Securing the Cloud
            </h1>
            <div className="flex flex-wrap justify-center">
            <div className="p-4 md:w-3/4 w-full">
                <div className="h-full bg-gray-800 bg-opacity-40 p-8 rounded">
                <p className="leading-relaxed mb-6 text-2xl">
                Cloud security is complicated, but it doesn't need to be. Navigate these complexities with Overkast Security - your trusted partner in safeguarding your digital future.
                </p>
                <div className="flex justify-center">
                    <button onClick={() => navigateToSection('contact')} className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                        Contact Us
                    </button>

                    <button onClick={() => navigateToSection('research')} className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                        View Research
                    </button>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

      <section id="services">
        <Services />
      </section>

      {/*<section id="testimonials">
        <Testimonials />
      </section>*/}

      <section id="research">
        <Research />
      </section>

      <section id="about">
        <About />
      </section>

      <section id="contact">
        <Contact />
      </section>

      <div>
        <p className="text-center underline pb-5 text-sm">© 2024, Overkast Security LLC. All Rights Reserved.</p>
      </div>

      {/* ... other sections ... */}
    </main>
  );
}

export default Home;
