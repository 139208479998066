import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";

export default function Navbar() {

    const navigateToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop = section.offsetTop - 60; // Adjust this value as needed for your layout
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

  return (
    <header className="bg-gray-800 md:sticky top-0 z-20">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <button onClick={() => navigateToSection('home')} className="title-font font-medium text-white mb-4 md:mb-0 ml-3 text-xl focus:outline-none">
          Overkast Security
        </button>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center text-base justify-center">
          <button onClick={() => navigateToSection('services')} className="mr-5 hover:text-white focus:outline-none">
            Services
          </button>
          {/*<button onClick={() => navigateToSection('testimonials')} className="mr-5 hover:text-white focus:outline-none">
            Testimonials
          </button>*/}
          <button onClick={() => navigateToSection('research')} className="mr-5 hover:text-white focus:outline-none">
            Research
          </button>
          <button onClick={() => navigateToSection('about')} className="mr-5 hover:text-white focus:outline-none">
            About
          </button>
        </nav>
        <button onClick={() => navigateToSection('contact')} className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0 focus:outline-none">
          Contact Us
          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </button>
      </div>
    </header>
  );
}
