import React from "react";
import { BookOpenIcon } from "@heroicons/react/solid";

export default function About() {
    return (
        <section id="about">
        <div className="container px-5 py-10 mx-auto text-center">
          <BookOpenIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-8">
            About
          </h1>
          <div className="flex flex-wrap m-4 justify-center">
            <div className="p-4 md:w-3/4 w-full">
              <div className="h-full bg-gray-800 bg-opacity-40 px-8 pt-8 pb-3 rounded">
                <p className="leading-relaxed mb-0 text-white text-xl">
                At Overkast Security, we're all about solving the most complicated problems with the simplest solutions. With 4+ years of experience at the world's top tech companies such as SpaceX and Google, we can provide the expertise required to solve any cloud security challenge your company might face. Use the contact form below to reach out for any more information or to schedule a consultation.
                </p>
                <div className="flex justify-center">
            <div className="flex items-center mb-4 flex-wrap justify-center">
              <img
                src="spacex_logo_2.png" 
                alt="SpaceX"
                className="w-32 opacity-50 px-3 pt-10 flex justify-center"
              />
              <img
                src="google_logo.png" 
                alt="Google"
                className="w-32 opacity-50 px-3 pt-10 justify-center" 
              />
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}