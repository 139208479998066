import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MailIcon, CheckIcon } from "@heroicons/react/outline";

export default function Contact() {
    const [showNotification, setShowNotification] = useState(false);
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fnw8f5s', 'template_nitd774', form.current, 'YTGxWlnTiFmpHD8RK')
      .then((result) => {
          console.log(result.text);
          // Reset the form after successful submission or show a success message
          e.target.reset();
          setShowNotification(true);
          setTimeout(() => setShowNotification(false), 2000); // Notification visible for 2 seconds
      }, (error) => {
          console.log(error.text);
          // Handle errors here
      });
  };

  return (
    <section id="contact" className="relative">
      <div className="container px-5 pb-3 mx-auto flex sm:flex-nowrap flex-wrap justify-center">
        {showNotification && <Notification />}
        <form
          ref={form}
          onSubmit={sendEmail}
          //netlify
          name="contact"
          className="lg:w-1/2 md:w-2/3 flex flex-col mx-auto w-full md:py-5 mt-8 md:mt-0">
          <div className="flex justify-center">
            <MailIcon className="w-10 mb-4" />
          </div>
          <div className="text-center">
            <h2 className="sm:text-4xl text-3xl font-medium title-font text-white mb-8">
              Contact Us
            </h2>
          </div>
          <div className="relative mb-4">
            <label htmlFor="user_name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="user_email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="user_email"
              name="user_email"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message" className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
                id="message"
                name="message"
                className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4 flex justify-center">
          <button
                type="submit"
                className="w-32 text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              Submit
          </button>
          </div>
          </form>
        </div>
    </section>
  );
}

const Notification = () => (
    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-b-md shadow-lg flex items-center justify-center space-x-2" style={{ zIndex: 50 }}>
      <CheckIcon className="h-6 w-6" />
      <span>Message Sent</span>
    </div>
  );